import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyFormRadio = lazy(
  () => import("./form-radio-n0gj0zhg.js").then((module) => ({
    default: module.FormRadio
  }))
);
const LazyRadio = lazy(
  () => import("./radio-DYKmxWnp.js").then((n) => n.r).then((module) => ({
    default: module.Radio
  }))
);
function FormRadio({
  uuid: _uuid,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyFormRadio, { ...props });
}
const useFormRadioContent = () => {
  return useMemo(
    () => ({
      formRadio: {
        component: FormRadio
      }
    }),
    []
  );
};
export {
  LazyFormRadio as FormRadio,
  LazyRadio as Radio,
  useFormRadioContent
};
